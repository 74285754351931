import logo from './img/logo.png';
import main1 from './img/Home/main1.jpg';
import main07 from './img/Home/main07.png';
import main06 from './img/Home/main06.png';
import main09 from './img/Home/main09.png';
import component from './img/Home/component.png';
import main11 from './img/Home/main11.png';
import main12 from './img/Home/main12.png';

export const images = [
    logo,main1,main12,main07,main06,main09,component,main11
];