import React from 'react';

import Fade from 'react-reveal/Fade';

import main from '../../img/MarginTrading/main.jpg'
import component from '../../img/MarginTrading/component.png'

import svg1 from '../../img/MarginTrading/svg1.svg'
import svg2 from '../../img/MarginTrading/svg2.svg'
import svg3 from '../../img/MarginTrading/svg3.svg'
import svg4 from '../../img/MarginTrading/svg4.svg'
import svg5 from '../../img/MarginTrading/svg5.svg'
import svg6 from '../../img/MarginTrading/svg6.svg'

import { useTranslation } from "react-i18next";

const data = [
    {
        title: 'margintrading.4',
        svg: svg1,
        text: 'margintrading.5'
    },
    {
        title: 'margintrading.6',
        svg: svg2,
        text: 'margintrading.7'
    },
    {
        title: 'margintrading.8',
        svg: svg3,
        text: 'margintrading.9'
    },
    {
        title: 'margintrading.10',
        svg: svg4,
        text: 'margintrading.11'
    },
    {
        title: 'margintrading.12',
        svg: svg5,
        text: 'margintrading.13'
    },
    {
        title: 'margintrading.14',
        svg: svg6,
        text: 'margintrading.15'
    },
]

function Main () {

    const { t } = useTranslation();

    return(
        <div className="bg-[#000C17] max-w-[1920px] mx-auto overflow-hidden relative">
            <img src={main} alt="" className='h-[1154px] w-full object-cover absolute'/>
            <div className='max-w-[1280px] mx-[20px] xl:mx-auto relative z-50'>
                <div className='flex flex-col xl:flex-row pt-[100px] justify-between'>
                    <div className='mt-[40px]'>
                        <Fade duration={2000}>
                            <h1 className="russo-one text-white text-[20px] md:text-[24px] xl:text-[48px]">{t('margintrading.1')}</h1>
                            <p className='font-[500] xl:max-w-[600px] text-[#00FFF0] roboto text-[16px] xl:text-[20px] mt-[20px]'>{t('margintrading.2')}</p>
                            <p className='font-[300] xl:max-w-[600px] text-white roboto text-[14px] xl:text-[16px] mt-[20px] xl:mt-[40px]'>{t('margintrading.3')}</p>
                        </Fade>
                    </div>
                    <div className='mt-[40px] xl:mt-0'>
                        <Fade right><img src={component} alt="" className='mx-auto'/></Fade>
                    </div>
                </div>
                <div className='flex flex-wrap mt-[60px] gap-[20px] xl:gap-[60px]'>
                    {data.map((data, index) => (
                        <Fade bottom>
                            <div key={index} className='block1 h-[270px]'>
                                <img src={data.svg} alt="" className='mx-[20px] xl:mx-[40px] mt-[20px] xl:mt-[30px]'/>
                                <p className='mx-[20px] xl:mx-[40px] mt-[20px] text-[16px] xl:text-[20px] text-[#00FFF0] uppercase russo-one'>{t(data.title)}</p>
                                <p className='mx-[20px] xl:mx-[40px] font-[300] text-white text-[14px] xl:text-[16px] roboto mt-[15px]'>{t(data.text)}</p>
                            </div>
                        </Fade>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Main;