import React from "react";

import logo from '../img/logo.png'

import { useTranslation } from "react-i18next";

const { hostname } = document.location;

function Footer () {

    const { t } = useTranslation();
      
    return(
        <div className="max-w-[1280px] xl:mx-auto mx-[20px] relative pt-[80px] xl:pt-[160px]">
            <div className="flex flex-col xl:flex-row items-start gap-[20px] xl:gap-[100px]">
                <img src={logo} alt=""/>
                <div className="flex flex-col">
                    <div className="flex flex-col xl:flex-row items-start gap-[10px] xl:gap-[200px]">
                        <div className="flex flex-col gap-[10px]">
                            <p className="text-white opacity-[0.7] roboto text-[14px] xl:text-[16px]">{t('footer.1')} +441241340827</p>
                            <p className="text-white opacity-[0.7] roboto text-[14px] xl:text-[16px]">{t('footer.2')} M Milas, 88a Meadow Street, Preston, United Kingdom, PR1 1TS</p>
                            <p className="text-white opacity-[0.7] roboto text-[14px] xl:text-[16px]">{t('footer.3')} support@{hostname}</p>
                        </div>
                        <div className="flex flex-col gap-[10px]">
                            <a href="/terms.pdf"><p className="text-white opacity-[0.7] roboto text-[14px] xl:text-[16px]">{t('footer.4')}</p></a>
                            <a href="/policy.pdf"><p className="text-white opacity-[0.7] roboto text-[14px] xl:text-[16px]">{t('footer.5')}</p></a>
                            <a href="/aml.pdf"><p className="text-white opacity-[0.7] roboto text-[14px] xl:text-[16px]">{t('footer.6')}</p></a>
                        </div>
                    </div>
                    <div className="pt-[20px] xl:pt-[40px]">
                        <p className="text-white opacity-[0.7] roboto text-[10px] xl:text-[12px]">{t('footer.7')}</p>
                        <p className="text-white opacity-[0.7] roboto text-[10px] xl:text-[12px]">{t('footer.8')}</p>
                    </div>
                    <p className="ml-auto pt-[10px] pb-[20px] text-white opacity-[0.7] roboto text-[14px] xl:text-[16px]">Frax Trade © 2025. All rights reserved.</p>
                </div>
              
            </div>
         
        </div>
    );
}

export default Footer;