import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from "swiper/modules";

import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css';

import Fade from 'react-reveal/Fade'

import main from '../../img/AccountTypes/main.png'
import ice from '../../img/AccountTypes/ice.png'
import svg from '../../img/AccountTypes/svg.svg'
import light from '../../img/AccountTypes/light.png'

import { useTranslation } from "react-i18next";

const swiperData = [
    {
        index: 0,
        title: 'accounttypes.2',
        text: [
            {
                text: 'accounttypes.3'
            },
            {
                text: 'accounttypes.4'
            },
            {
                text: 'accounttypes.5'
            }
        ]
    },
    {
        index: 1,
        title: 'accounttypes.6',
        text: [
            {
                text: 'accounttypes.7'
            },
            {
                text: 'accounttypes.8'
            },
            {
                text: 'accounttypes.9'
            }
        ]
    },
    {
        index: 2,
        title: 'accounttypes.10',
        text: [
            {
                text: 'accounttypes.11'
            },
            {
                text: 'accounttypes.12'
            },
            {
                text: 'accounttypes.13'
            }
        ]
    },
    {
        index: 3,
        title: 'accounttypes.14',
        text: [
            {
                text: 'accounttypes.15'
            },
            {
                text: 'accounttypes.16'
            },
            {
                text: 'accounttypes.17'
            }
        ]
    }
];

function Main () {

    const [swiper, setSwiper] = useState({});

    const { t } = useTranslation();

    return(
        <div className="bg-[#000C17] max-w-[1920px] mx-auto overflow-hidden relative">
            <div className="left-blur"></div>
            <div className="right-blur"></div>
            <img src={main} alt="" className='object-cover w-full absolute h-full xl:h-auto'/>
            <div className='max-w-[800px] mx-auto relative flex z-[999]'>
                <div onClick={() => { swiper.slidePrev() }} className="cursor-pointer absolute top-[560px] left-[20px] xl:left-0 sm:top-[280px] xl:top-[380px]">
                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                        <g filter="url(#filter0_bf_1545_96)">
                            <rect x="4" y="4" width="40" height="40" rx="20" fill="white" fill-opacity="0.06"/>
                            <rect x="4.5" y="4.5" width="39" height="39" rx="19.5" stroke="white"/>
                        </g>
                        <path d="M15 24L20 26.8868L20 21.1132L15 24ZM33 23.5L19.5 23.5L19.5 24.5L33 24.5L33 23.5Z" fill="white"/>
                        <defs>
                            <filter id="filter0_bf_1545_96" x="-18" y="-18" width="84" height="84" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feGaussianBlur in="BackgroundImageFix" stdDeviation="11"/>
                            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1545_96"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_1545_96" result="shape"/>
                            <feGaussianBlur stdDeviation="2" result="effect2_foregroundBlur_1545_96"/>
                            </filter>
                        </defs>
                    </svg>
                </div>
                <div onClick={() => { swiper.slideNext() }} className="cursor-pointer right-[20px] xl:right-0 absolute top-[560px] sm:top-[280px] xl:top-[380px]">
                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                        <g filter="url(#filter0_bf_1545_93)">
                            <rect x="44" y="44" width="40" height="40" rx="20" transform="rotate(180 44 44)" fill="white" fill-opacity="0.06"/>
                            <rect x="43.5" y="43.5" width="39" height="39" rx="19.5" transform="rotate(180 43.5 43.5)" stroke="white"/>
                        </g>
                        <path d="M33 24L28 21.1132L28 26.8867L33 24ZM15 24.5L28.5 24.5L28.5 23.5L15 23.5L15 24.5Z" fill="white"/>
                        <defs>
                            <filter id="filter0_bf_1545_93" x="-18" y="-18" width="84" height="84" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feGaussianBlur in="BackgroundImageFix" stdDeviation="11"/>
                            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1545_93"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_1545_93" result="shape"/>
                            <feGaussianBlur stdDeviation="2" result="effect2_foregroundBlur_1545_93"/>
                            </filter>
                        </defs>
                    </svg>
                </div>
            </div> 
            <div className='max-w-[700px] mx-auto pb-[200px] xl:pb-0 xl:h-screen mt-[100px] xl:mt-[200px]'>
                <div className='bg-[#03192F] sm:mx-[40px] md:w-[600px] h-[500px] xl:h-[400px] rounded-[20px] absolute hidden xl:block'></div>
                <Swiper
                    modules={[Navigation]}
                    slidesPerView={1}
                    onInit={(e) => {
                        setSwiper(e);
                    }}
                    loop={true}
                >
                    {swiperData.map((slide, index) => (
                        <SwiperSlide key={index}>
                            <div className='mx-[20px] rounded-[20px] md:w-[600px] h-[450px] bg-[#03192F] xl:bg-transparent xl:h-[400px] md:mx-auto flex flex-col relative z-50'>
                                <h2 className='text-[#00FFF0] text-[20px] xl:text-[32px] mx-auto russo-one mt-[35px]'>{t(slide.title)}</h2>
                                {slide.text.map((item, idx) => (
                                    <div key={idx} className='flex gap-[20px] mx-[10px] xl:mx-[50px] items-start mt-[20px]'>
                                        <img src={svg} alt="" className='mt-[5px]'/>
                                        <p className='text-white roboto font-[300] text-[14px]'>{t(item.text)}</p>
                                    </div>
                                ))}
                                <Link to='https://user.frax-trade.net' className="button mt-[30px] cursor-pointer mx-auto">
                                    <div>
                                        <p className="russo-one text-[14px] xl:text-[20px] text-white uppercase">{t('accounttypes.1')}</p>
                                    </div>
                                </Link>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className='hidden xl:block absolute top-[50px] z-0 moving-iceberg'>
                    <Fade delay={1000}>
                        <img src={light} alt=""/>
                    </Fade>
                </div>
                <Fade bottom>
                    <img src={ice} alt="" className='hidden xl:block mx-auto relative z-20'/>
                </Fade>
            </div>
        </div>
    );
}

export default Main;