import React, { useState } from 'react';

import Footer from '../Footer'

import img from '../../img/Home/footer.png'
import switch1 from '../../img/AboutUs/switch5.png'
import switch2 from '../../img/AboutUs/switch4.png'
import switch3 from '../../img/AboutUs/switch3.png'
import switch4 from '../../img/AboutUs/switch2.png'
import switch5 from '../../img/AboutUs/switch1.png'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from "swiper/modules";

import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css';

import { useTranslation } from "react-i18next";

function WhatTradersSay () {

    const [swiper, setSwiper] = useState({});

    const { t } = useTranslation();

    return(
        <div className='max-w-[1920px] mx-auto overflow-hidden relative mt-[170px] xl:mt-[160px]'>
            <img src={img} alt="" className='absolute w-full'/>
            <div className='max-w-[1220px] mx-[20px] xl:mx-auto z-50 relative flex flex-col xl:flex-row justify-between'>
                <p className='text-white uppercase russo-one text-[20px] xl:text-[32px] xl:max-w-[557px]'>{t('aboutus.30')}</p>
                <div className='flex justify-between gap-[20px] mt-[20px]'>
                    <div onClick={() => { swiper.slidePrev(); }} className="cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                            <g filter="url(#filter0_bf_1545_96)">
                                <rect x="4" y="4" width="40" height="40" rx="20" fill="white" fill-opacity="0.06"/>
                                <rect x="4.5" y="4.5" width="39" height="39" rx="19.5" stroke="white"/>
                            </g>
                            <path d="M15 24L20 26.8868L20 21.1132L15 24ZM33 23.5L19.5 23.5L19.5 24.5L33 24.5L33 23.5Z" fill="white"/>
                            <defs>
                                <filter id="filter0_bf_1545_96" x="-18" y="-18" width="84" height="84" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                <feGaussianBlur in="BackgroundImageFix" stdDeviation="11"/>
                                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1545_96"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_1545_96" result="shape"/>
                                <feGaussianBlur stdDeviation="2" result="effect2_foregroundBlur_1545_96"/>
                                </filter>
                            </defs>
                        </svg>
                    </div>
                    <div onClick={() => { swiper.slideNext() }} className="cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                            <g filter="url(#filter0_bf_1545_93)">
                                <rect x="44" y="44" width="40" height="40" rx="20" transform="rotate(180 44 44)" fill="white" fill-opacity="0.06"/>
                                <rect x="43.5" y="43.5" width="39" height="39" rx="19.5" transform="rotate(180 43.5 43.5)" stroke="white"/>
                            </g>
                            <path d="M33 24L28 21.1132L28 26.8867L33 24ZM15 24.5L28.5 24.5L28.5 23.5L15 23.5L15 24.5Z" fill="white"/>
                            <defs>
                                <filter id="filter0_bf_1545_93" x="-18" y="-18" width="84" height="84" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                <feGaussianBlur in="BackgroundImageFix" stdDeviation="11"/>
                                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1545_93"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_1545_93" result="shape"/>
                                <feGaussianBlur stdDeviation="2" result="effect2_foregroundBlur_1545_93"/>
                                </filter>
                            </defs>
                        </svg>
                    </div>
                </div> 
            </div>
            <Swiper
                modules={[Navigation]}
                slidesPerView={3.3}
                onInit={(e) => {
                    setSwiper(e);
                }}
                className='mt-[20px] xl:mt-[60px]'
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                    },
                    640: {
                        slidesPerView: 2,
                    },
                    1280: {
                        slidesPerView: 2.5,
                    },
                    1920: {
                        slidesPerView: 3.3,
                    },
                }}
            >
                <SwiperSlide>
                    <div className='mx-[20px] xl:mx-0 xl:w-[527px] h-[310px] rounded-[10px] bg-[#03192F]'>
                        <div className='flex pt-[20px] ml-[20px] xl:pt-[40px] xl:ml-[40px] items-end'>
                            <img src={switch1} alt="" />
                            <p className='ml-[20px] text-[#FFFFFFB2] roboto text-[14px] xl:text-[16px]'>Sarah M.</p>
                        </div>
                        <p className='text-[#FFFFFFB2] roboto text-[14px] xl:text-[16px] mx-[20px] xl:mx-[40px] mt-[20px]'>{t('aboutus.31')}</p>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='mx-[20px] xl:mx-0 xl:w-[527px] h-[310px] rounded-[10px] bg-[#03192F]'>
                        <div className='flex pt-[20px] ml-[20px] xl:pt-[40px] xl:ml-[40px] items-end'>
                            <img src={switch2} alt="" />
                            <p className='ml-[20px] text-[#FFFFFFB2] roboto text-[14px] xl:text-[16px]'>John D.</p>
                        </div>
                        <p className='text-[#FFFFFFB2] roboto text-[14px] xl:text-[16px] mx-[20px] xl:mx-[40px] mt-[20px]'>{t('aboutus.32')}</p>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='mx-[20px] xl:mx-0 xl:w-[527px] h-[310px] rounded-[10px] bg-[#03192F]'>
                        <div className='flex pt-[20px] ml-[20px] xl:pt-[40px] xl:ml-[40px] items-end'>
                            <img src={switch3} alt="" />
                            <p className='ml-[20px] text-[#FFFFFFB2] roboto text-[14px] xl:text-[16px]'>Lisa R.</p>
                        </div>
                        <p className='text-[#FFFFFFB2] roboto text-[14px] xl:text-[16px] mx-[20px] xl:mx-[40px] mt-[20px]'>{t('aboutus.33')}</p>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='mx-[20px] xl:mx-0 xl:w-[527px] h-[310px] rounded-[10px] bg-[#03192F]'>
                        <div className='flex pt-[20px] ml-[20px] xl:pt-[40px] xl:ml-[40px] items-end'>
                            <img src={switch4} alt="" />
                            <p className='ml-[20px] text-[#FFFFFFB2] roboto text-[14px] xl:text-[16px]'>Mike H.</p>
                        </div>
                        <p className='text-[#FFFFFFB2] roboto text-[14px] xl:text-[16px] mx-[20px] xl:mx-[40px] mt-[20px]'>{t('aboutus.34')}</p>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='mx-[20px] xl:mx-0 xl:w-[527px] h-[310px] rounded-[10px] bg-[#03192F]'>
                        <div className='flex pt-[20px] ml-[20px] xl:pt-[40px] xl:ml-[40px] items-end'>
                            <img src={switch5} alt="" />
                            <p className='ml-[20px] text-[#FFFFFFB2] roboto text-[14px] xl:text-[16px]'>David S.</p>
                        </div>
                        <p className='text-[#FFFFFFB2] roboto text-[14px] xl:text-[16px] mx-[20px] xl:mx-[40px] mt-[20px]'>{t('aboutus.35')}</p>
                    </div>
                </SwiperSlide>
            </Swiper>
            <Footer/>
        </div>
    );
}

export default WhatTradersSay;