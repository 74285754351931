import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom"

import logo from "../img/logo.png";

import { useTranslation } from "react-i18next";

function MobileMenu( { className, colorbars, bg } ) {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const location = useLocation();
    console.log(colorbars)

    const { t } = useTranslation();

    return (
        <div className={className}>
            <div className="relative z-[999] w-[30px] h-[25px] cursor-pointer flex flex-col justify-between" onClick={toggleMenu}>
                <div className={`w-full h-[1px] transition duration-300 transform ${isOpen ? `rotate-45 translate-y-[9px] bg-[#00FFF0] w-[35px]` : `bg-[#00FFF0]`}`}></div>
                <div className={`w-full h-[1px] bg-[#00FFF0] transition duration-300 transform ${isOpen ? 'hidden' : 'block'}`}></div>
                <div className={`w-full h-[1px]  transition duration-300 transform ${isOpen ? `-rotate-45 -translate-y-[15px] bg-[#00FFF0] w-[35px]` : `bg-[#00FFF0]`}`}></div>
            </div>
            <div className={`z-[600] fixed top-0 right-0 bottom-0 w-full md:w-1/2 bg-[${bg}] z-50 transform transition-all duration-300 ${isOpen ? 'translate-x-0' : 'translate-x-full'}`}>
                <div className="flex flex-col items-center mt-[50px] gap-[30px]">
                    <img src={logo} alt="" className="w-[40px]"/>
                    <Link to="/margintrading"><p className={`text-[18px] inter ${location.pathname === '/margintrading' ? 'font-medium text-[#00FFF0]' : 'font-light text-white opacity-[0.7]'}`} onClick={toggleMenu}>{t('header.2')}</p></Link>
                    <Link to="/slip"><p className={`text-[18px] inter ${location.pathname === '/slip' ? 'font-medium text-[#00FFF0]' : 'font-light text-white opacity-[0.7]'}`} onClick={toggleMenu}>{t('header.3')}</p></Link>
                    <Link to="/cashmanagment"><p className={`text-[18px] inter ${location.pathname === '/cashmanagment' ? 'font-medium text-[#00FFF0]' : 'font-light text-white opacity-[0.7]'}`} onClick={toggleMenu}>{t('header.4')}</p></Link>
                    <Link to="/trading"><p className={`text-[18px] inter ${location.pathname === '/trading' ? 'font-medium text-[#00FFF0]' : 'font-light text-white opacity-[0.7]'}`} onClick={toggleMenu}>{t('header.5')}</p></Link>
                    <Link to="/account"><p className={`text-[18px] inter ${location.pathname === '/account' ? 'font-medium text-[#00FFF0]' : 'font-light text-white opacity-[0.7]'}`} onClick={toggleMenu}>{t('header.6')}</p></Link>
                    <Link to="/about"><p className={`text-[18px] inter ${location.pathname === '/about' ? 'font-medium text-[#00FFF0]' : 'font-light text-white opacity-[0.7]'}`} onClick={toggleMenu}>{t('header.7')}</p></Link>
                </div>
            </div>
        </div>
    );
}

export default MobileMenu;