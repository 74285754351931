import React from 'react';

import { Link } from 'react-router-dom';

import Fade from "react-reveal/Fade";

import gif from '../../img/Home/giphy.gif'

import main1 from '../../img/Home/main1.jpg'

import main01 from '../../img/Home/main07.png'
import main03 from '../../img/Home/main09.png'
import main04 from '../../img/Home/main15.png'
import main05 from '../../img/Home/main12.png'
import main06 from '../../img/Home/main13.png'
import main07 from '../../img/Home/main11.png'
import main08 from '../../img/Home/main14.png'
import main09 from '../../img/Home/main10.png'

import main10 from '../../img/Home/main06.png'
import component from '../../img/Home/component.png'

import svg1 from '../../img/Home/svg1-01.svg'
import svg2 from '../../img/Home/svg1-02.svg'
import svg3 from '../../img/Home/svg1-03.svg'
import svg4 from '../../img/Home/svg1-04.svg'
import svg5 from '../../img/Home/svg1-05.svg'

import { useTranslation } from "react-i18next";

const data = [
    {
        name: 'main.5',
        text: 'main.6',
        svg: svg1
    },
    {
        name: 'main.7',
        text: 'main.8',
        svg: svg2
    },
    {
        name: 'main.9',
        text: 'main.10',
        svg: svg3
    },
    {
        name: 'main.11',
        text: 'main.12',
        svg: svg4
    },
    {
        name: 'main.13',
        text: 'main.14',
        svg: svg5
    },
];

function Main () {

    const { t } = useTranslation();

    return(
        <div className="bg-[#000C17]">
            <div className="max-w-[1920px] mx-auto relative overflow-hidden">
                <div className="left-blur"></div>
                <div className="right-blur"></div>
                <div className="relative flex flex-col h-[328.054px] sm:h-[550px] md:h-[720px]">
                    <img src={main1} alt="" className="w-full h-[328.054px] sm:h-[550px] md:h-[720px] object-cover"/>
                    <div className="max-w-[800px] absolute mt-[50px] sm:mt-[110px] inset-0 mx-auto">
                        <img src={main04} alt="" className="moving-block3 absolute scale-75 xl:scale-100"/>
                        <div className="absolute left-[-100px] top-[50px] xl:top-[100px] scale-50 xl:scale-100">
                            <Fade left bottom delay={600} duration={1000}>
                                <img src={main05} alt=""/>
                            </Fade>                            
                        </div>
                        <div className="absolute left-[50px] top-[50px] scale-50 xl:scale-100">
                            <Fade left top duration={1000}>
                                <img src={main06} alt=""/>
                            </Fade>
                        </div>
                        <p className="russo-one text-[20px] md:text-[24px] xl:text-[48px] text-white leading-[1.4] text-center z-50 relative">{t('main.1')}</p>
                        <p className="russo-one text-[32px] md:text-[48px] xl:text-[40px] leading-[1.4] text-white text-center z-50 relative">{t('main.2')}</p>
                        <div  className="absolute right-0 top-0 xl:top-[100px] scale-50 xl:scale-100">
                            <Fade right top duration={1000}>
                                <img src={main07} alt=""/>
                            </Fade>
                        </div>      
                        <img src={main08} alt="" className="moving-block absolute right-0 xl:right-[-100px] scale-50 xl:scale-100 top-0"/>
                        <img src={main09} alt="" className="moving-block1 absolute bottom-0 xl:bottom-[300px] right-0 xl:right-[-70px] scale-50 xl:scale-100"/>
                        <Link to='https://user.frax-trade.net'>
                            <div className="button relative z-[500] left-1/2 transform -translate-x-1/2 mt-[70px] xl:mt-[100px] cursor-pointer">
                                <p className="russo-one text-[14px] xl:text-[20px] text-white">{t('main.3')}</p>
                            </div>
                        </Link>
                    </div>
                    <img src={gif} alt="" className="mix-blend-lighten opacity-35 absolute bottom-[-50px] sm:bottom-[-100px] xl:bottom-[40px] left-1/2 transform -translate-x-1/2 scale-75 xl:scale-100"/>
                    <div className="absolute bottom-[0px] sm:bottom-[20px] md:bottom-[-20px] z-[50] xl:bottom-[20px] 2xl:bottom-[100px] left-1/2 transform -translate-x-1/2">
                        <div className="moving-iceberg overflow-hidden">
                            <Fade bottom duration={1500}>
                                <img src={main03} alt=""/>
                            </Fade>
                        </div>
                    </div>
                    <img src={main01} alt="" className="z-50 absolute bottom-[-30px] md:bottom-[-100px] w-full"/>
                </div>
                <div className="relative flex flex-col bg-[#000C17]">
                    <div className="z-10 h-[200px] sm:h-auto top-[-20px] sm:top-[-50px] md:top-[-20px] xl:top-[-60px] absolute left-1/2 transform -translate-x-1/2">
                        <div className="moving-iceberg overflow-hidden">
                            <Fade top duration={1500}>
                                <img src={main10} alt=""/>
                            </Fade>
                        </div>
                    </div> 
                    <div className="absolute top-[-20px] xl:top-[-100px] left-1/2 transform -translate-x-1/2 scale-75 xl:scale-100 z-10">
                        <div className='moving-block2'>
                            <Fade duration={1500}>
                                <img src={component} alt=""/>
                            </Fade>
                        </div>
                    </div>
                    <div className="main2 w-full h-[486.614px] md:h-[825.466px] xl:h-[1068px]"></div>
                    <div className="max-w-[1280px] mt-[-370px] md:mt-[-500px] xl:mt-[-500px] mx-auto relative z-10">
                        <Fade bottom><p className="max-w-[447px] text-white text-center sm:mx-auto roboto text-[14px] xl:text-[20px] mx-[20px]">{t('main.4')}</p></Fade>
                        <div className="flex flex-wrap gap-[50px] mt-[50px]">
                            <Fade bottom delay={500} duration={1000}>{data.map((item, index) => (
                                <div className="block1 h-[250px] mx-[20px] xl:mx-auto" key={index}>
                                    <div className="mx-[40px]">
                                        <img src={item.svg} alt="" className="mt-[30px]"/>
                                        <p className="uppercase text-[#00FFF0] russo-one mt-[20px] text-[16px] xl:text-[20px]">{t(item.name)}</p>
                                        <p className="text-[14px] xl:text-[16px] text-white font-[300] mt-[12px]">{t(item.text)}</p>
                                    </div> 
                                </div>
                            ))}</Fade>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
    );
}

export default Main;