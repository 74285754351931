import React from 'react';

import Fade from 'react-reveal/Fade'

import ice from '../../img/AboutUs/ice2.png'
import svg from '../../img/AccountTypes/svg.svg'

import { useTranslation } from "react-i18next";

function MainAdvantages () {

    const { t } = useTranslation();

    return(
        <div className='max-w-[1920px] mx-auto relative'>
            <div className='max-w-[1220px] mx-[20px] xl:mx-auto relative mt-[80px] xl:mt-[160px]'>
                <div className='flex flex-col xl:flex-row justify-between'>
                    <div>
                        <p className='text-white text-[24px] xl:text-[40px] russo-one uppercase'>{t('aboutus.20')}</p>
                        <Fade left>
                            <img src={ice} alt="" className='absolute top-[50px] left-[-600px] hidden xl:block'/>
                        </Fade>
                    </div>
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-x-[100px]'>
                        <div className='flex gap-[30px] mt-[20px] xl:mt-0'>
                            <img src={svg} alt="" className='w-[14px] h-[14px] mt-[7px]'/>
                            <p className='text-[14px] xl:text-[20px] roboto text-white'>{t('aboutus.21')}</p>
                        </div>
                        <div className='flex gap-[30px] mt-[20px] xl:mt-0' >
                            <img src={svg} alt="" className='w-[14px] h-[14px] mt-[7px]'/>
                            <p className='text-[14px] xl:text-[20px] roboto text-white'>{t('aboutus.22')}</p>
                        </div>
                        <div className='flex gap-[30px] mt-[20px]'>
                            <img src={svg} alt="" className='w-[14px] h-[14px] mt-[7px]'/>
                            <p className='text-[14px] xl:text-[20px] roboto text-white'>{t('aboutus.23')}</p>
                        </div>
                        <div className='flex gap-[30px] mt-[20px]'>
                            <img src={svg} alt="" className='w-[14px] h-[14px] mt-[7px]'/>
                            <p className='text-[14px] xl:text-[20px] roboto text-white'>{t('aboutus.24')}</p>
                        </div>
                        <div className='flex gap-[30px] mt-[20px]'>
                            <img src={svg} alt="" className='w-[14px] h-[14px] mt-[7px]'/>
                            <p className='text-[14px] xl:text-[20px] roboto text-white'>{t('aboutus.25')}</p>
                        </div>
                        <div className='flex gap-[30px] mt-[20px]'>
                            <img src={svg} alt="" className='w-[14px] h-[14px] mt-[7px]'/>
                            <p className='text-[14px] xl:text-[20px] roboto text-white'>{t('aboutus.26')}</p>
                        </div>
                        <div className='flex gap-[30px] mt-[20px]'>
                            <img src={svg} alt="" className='w-[14px] h-[14px] mt-[7px]'/>
                            <p className='text-[14px] xl:text-[20px] roboto text-white'>{t('aboutus.27')}</p>
                        </div>
                        <div className='flex gap-[30px] mt-[20px]'>
                            <img src={svg} alt="" className='w-[14px] h-[14px] mt-[7px]'/>
                            <p className='text-[14px] xl:text-[20px] roboto text-white'>{t('aboutus.28')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MainAdvantages;