import React, { useState, useEffect } from "react";

import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

import { Link, useLocation } from "react-router-dom"
import MobileMenu from "./MobileMenu";
import logo from '../img/logo.png'

import { useTranslation } from "react-i18next";
import i18n from "i18next";
import Cookies from "js-cookie";

function Header () {

    const { t } = useTranslation();

    const languages = [
        { value: 'en', label: 'EN' },
        { value: 'ru', label: 'RU' },
        { value: 'ar', label: 'AR' },
    ]

    const storedLang = Cookies.get("i18next");
    const [currentLang, setCurrentLang] = useState(storedLang || 'en');

    useEffect(() => {
        if (storedLang && storedLang !== currentLang) {
            setCurrentLang(storedLang);
            i18n.changeLanguage(storedLang);
        }
        // eslint-disable-next-line
    }, [storedLang]);
    

    const handleChangeLang = (selectedLang) => {
        setCurrentLang(selectedLang.value);
        i18n.changeLanguage(selectedLang.value);
        Cookies.set("i18next", selectedLang.value);
    };

    const [isProductsOpen, setIsProductsOpen] = useState(false);

    const location = useLocation();

    const toggleProductsMenu = () => {
        setIsProductsOpen(!isProductsOpen);
    };

    const closeProductsMenu = () => {
        setIsProductsOpen(false);
    };

    useEffect(() => {
        const handleScroll = () => {
          setIsProductsOpen(false);
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
      
    return(
        <div className="max-w-[1280px] mx-auto">
            <div className="hidden fixed bg-[#03192F] mt-[20px] px-[20px] rounded-[30px] h-[80px] z-[999] xl:flex items-center gap-[60px] w-[1280px]">
                <Link to="/"><img src={logo} alt="" className="h-[60px]"/></Link>
                <div className="flex items-center gap-[5px] cursor-pointer" onClick={toggleProductsMenu}>
                    <p className={`roboto text-[16px] ${location.pathname === '/margintrading' || location.pathname === '/cashmanagment' || location.pathname === '/slip' ? 'text-[#00FFF0]' : 'text-white opacity-[0.7]'}`}>{t('header.1')}</p>
                    <svg className={isProductsOpen ? "-rotate-180 transition-all" : "rotate-0 transition-all"} xmlns="http://www.w3.org/2000/svg" width="13" height="7" viewBox="0 0 13 7" fill="none">
                        <path d="M1 1L6.66667 6L12 1" stroke="white" stroke-opacity="0.7" stroke-width="1.2"/>
                    </svg>
                    {isProductsOpen && (
                        <div className="absolute w-[200px] h-[128px] bg-[#000C17] top-[50px] flex flex-col justify-center gap-[10px]">
                            <Link to="/margintrading"><p className={`roboto ml-[15px] ${location.pathname === '/margintrading' ? 'text-[#00FFF0]' : 'text-white opacity-[0.7]'}`}>{t('header.2')}</p></Link>
                            <Link to="/slip"><p className={`roboto ml-[15px] ${location.pathname === '/slip' ? 'text-[#00FFF0]' : 'text-white opacity-[0.7]'}`}>{t('header.3')}</p></Link>
                            <Link to="/cashmanagment"><p className={`roboto ml-[15px] ${location.pathname === '/cashmanagment' ? 'text-[#00FFF0]' : 'text-white opacity-[0.7]'}`}>{t('header.4')}</p></Link>
                        </div>
                    )}
                </div>
                <Link to="/trading" onClick={closeProductsMenu}><p className={`text-[16px] roboto ${location.pathname === '/trading' ? 'text-[#00FFF0]' : 'text-white opacity-[0.7]'}`}>{t('header.5')}</p></Link>
                <Link to="/account" onClick={closeProductsMenu}><p className={`text-[16px] roboto ${location.pathname === '/account' ? 'text-[#00FFF0]' : 'text-white opacity-[0.7]'}`}>{t('header.6')}</p></Link>
                <Link to="/about" onClick={closeProductsMenu}><p className={`text-[16px] roboto ${location.pathname === '/about' ? 'text-[#00FFF0]' : 'text-white opacity-[0.7]'}`}>{t('header.7')}</p></Link>
                <div className="flex items-center gap-[30px] ml-auto">
                    <Dropdown
                        onClick={closeProductsMenu} 
                        options={languages}
                        placeholder="EN"
                        onChange={handleChangeLang}
                        value={languages.find(lang => lang.value === currentLang)}
                    />
                    <Link to='https://user.frax-trade.net'>
                        <p className="uppercase text-[#00FFF0] russo-one text-[20px]">{t('header.8')}</p>
                    </Link>
                </div>
            </div>
            <div className="xl:hidden w-full flex fixed z-[999] items-center justify-between">
                <Link to="/"><img src={logo} alt="" className="ml-[20px] mt-[10px]"/></Link>
                <div className="flex items-center gap-[10px]">
                    <Dropdown
                        onClick={closeProductsMenu} 
                        options={languages}
                        placeholder="EN"
                        onChange={handleChangeLang}
                        value={languages.find(lang => lang.value === currentLang)}
                        className="mt-[10px]"
                    />
                    <MobileMenu bg='#000C17' colorbars='' className="mr-[20px] mt-[10px]"/>
                </div>      
            </div>
        </div>
    );
}

export default Header;