import React, { useEffect } from "react";
import LazyLoad from "react-lazyload";

import Main from "../components/MarginTrading/Main";
import CashMarginAccount from "../components/MarginTrading/CashMarginAccount";
import HowStart from "../components/MarginTrading/HowStart";
import ShortSelling from "../components/MarginTrading/ShortSelling";
import MarginFAQs from "../components/MarginTrading/MarginFAQs";


function MarginTrading () {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div className="bg-[#000C17]">
            <Main/>
            <LazyLoad><CashMarginAccount/></LazyLoad>
            <LazyLoad><HowStart/></LazyLoad>
            <LazyLoad><ShortSelling/></LazyLoad>
            <LazyLoad><MarginFAQs/></LazyLoad>
        </div>
    );
}

export default MarginTrading;