import React, { useEffect } from "react";
import Main from "../components/AboutUs/Main";
import MainAdvantages from "../components/AboutUs/MainAdvantages";
import OurDeposit from "../components/AboutUs/OurDeposit";
import ABrandBuilt from "../components/AboutUs/ABrandBuilt";
import WhatTradersSay from "../components/AboutUs/WhatTradersSay";

function AboutUs () {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div className="bg-[#000C17]">
            <Main/>
            <MainAdvantages/>
            <OurDeposit/>
            <ABrandBuilt/>
            <WhatTradersSay/>
        </div>
    );
}

export default AboutUs;