import React, { useState } from 'react';

import comp from '../../img/AccountTypes/comp.png'
import Footer from '../Footer';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from "swiper/modules";

import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css';

import { useTranslation } from "react-i18next";

function AccountComparison () {

    const [swiper1, setSwiper] = useState({});

    const { t } = useTranslation();

    return(
        <div className='max-w-[1920px] mx-auto bg-[#000C17]'>
            <img src={comp} alt="" className='absolute w-full object-cover'/>
            <div className='blockcomp w-full z-50 h-[342px] absolute'></div>
            <div className="max-w-[1220px] mx-auto z-[500] relative flex flex-col">
                <p className='text-white mx-auto russo-one uppercase text-[20px] xl:text-[32px]'>{t('accounttypes.18')}</p>
                <div className='xl:flex mt-[40px] mx-auto hidden'>
                    <div className='w-[190px] h-[645px]'>
                        <p className='text-white font-[300] text-[14px] roboto mt-[86px]'>{t('accounttypes.19')}</p>
                        <p className='text-white font-[300] text-[14px] roboto mt-[20px]'>{t('accounttypes.20')}</p>
                        <p className='text-white font-[300] text-[14px] roboto mt-[20px]'>{t('accounttypes.21')}</p>
                        <p className='text-white font-[300] text-[14px] roboto mt-[20px]'>{t('accounttypes.22')}</p>
                        <p className='text-white font-[300] text-[14px] roboto mt-[41px]'>{t('accounttypes.23')}</p>
                        <p className='text-white font-[300] text-[14px] roboto mt-[41px]'>{t('accounttypes.24')}</p>
                        <p className='text-white font-[300] text-[14px] roboto mt-[20px]'>{t('accounttypes.25')}</p>
                        <p className='text-white font-[300] text-[14px] roboto mt-[20px]'>{t('accounttypes.26')}</p>
                        <p className='text-white font-[300] text-[14px] roboto mt-[20px]'>{t('accounttypes.27')}</p>
                        <p className='text-white font-[300] text-[14px] roboto mt-[20px]'>{t('accounttypes.28')}</p>
                        <p className='text-white font-[300] text-[14px] roboto mt-[20px]'>{t('accounttypes.29')}</p>
                    </div>
                    <div className='w-[267px] h-[645px] rounded-[20px] bg-[#03192F] flex flex-col items-center'>
                        <p className='roboto text-[14px] font-[500] text-[#00FFF0] mt-[40px]'>{t('accounttypes.30')}</p>
                        <p className='text-white text-[14px] roboto mt-[20px]'>USD 250</p>
                        <p className='text-white text-[14px] roboto mt-[20px]'>0.5 pips</p>
                        <p className='text-white text-[14px] roboto mt-[20px]'>0.01 lots</p>
                        <p className='text-white text-[14px] roboto mt-[20px]'>-</p>
                        <p className='text-white text-[14px] roboto mt-[45px] text-center mx-[12px]'>{t('accounttypes.31')}</p>
                        <p className='text-white text-[14px] roboto mt-[20px]'>{t('accounttypes.32')}</p>
                        <p className='text-white text-[14px] roboto mt-[20px]'>50%</p>
                        <p className='text-white text-[14px] roboto mt-[41px]'>{t('accounttypes.33')}</p>
                        <p className='text-white text-[14px] roboto mt-[20px]'>25/15</p>
                        <p className='text-white text-[14px] roboto mt-[20px]'>{t('accounttypes.34')}</p>
                        <p className='text-white text-[14px] roboto mt-[20px] mx-[25px] text-center'>{t('accounttypes.35')}</p>
                    </div>
                    <div className='w-[267px] h-[645px] rounded-[20px] bg-transparent flex flex-col items-center'>
                        <p className='roboto text-[14px] font-[500] text-[#00FFF0] mt-[40px]'>{t('accounttypes.36')}</p>
                        <p className='text-white text-[14px] roboto mt-[20px]'>USD 2.500</p>
                        <p className='text-white text-[14px] roboto mt-[20px]'>1.5 pips</p>
                        <p className='text-white text-[14px] roboto mt-[20px]'>0.01 lots</p>
                        <p className='text-white text-[14px] roboto mt-[20px]'>-</p>
                        <p className='text-white text-[14px] roboto mt-[45px] text-center mx-[12px]'>{t('accounttypes.37')}</p>
                        <p className='text-white text-[14px] roboto mt-[20px]'>{t('accounttypes.38')}</p>
                        <p className='text-white text-[14px] roboto mt-[20px]'>50%</p>
                        <p className='text-white text-[14px] roboto mt-[41px]'>{t('accounttypes.39')}</p>
                        <p className='text-white text-[14px] roboto mt-[20px]'>25/15</p>
                        <p className='text-white text-[14px] roboto mt-[20px]'>{t('accounttypes.40')}</p>
                        <p className='text-white text-[14px] roboto mt-[20px] mx-[25px] text-center'>{t('accounttypes.41')}</p>
                    </div>
                    <div className='w-[267px] h-[645px] rounded-[20px] bg-[#03192F] flex flex-col items-center'>
                        <p className='roboto text-[14px] font-[500] text-[#00FFF0] mt-[40px]'>{t('accounttypes.42')}</p>
                        <p className='text-white text-[14px] roboto mt-[20px]'>USD 5.000</p>
                        <p className='text-white text-[14px] roboto mt-[20px]'>0 pips</p>
                        <p className='text-white text-[14px] roboto mt-[20px]'>0.01 lots</p>
                        <p className='text-white text-[14px] roboto mt-[20px] text-center mx-[20px]'>{t('accounttypes.43')}</p>
                        <p className='text-white text-[14px] roboto mt-[22px] text-center mx-[12px]'>-</p>
                        <p className='text-white text-[14px] roboto mt-[41px]'>{t('accounttypes.44')}</p>
                        <p className='text-white text-[14px] roboto mt-[20px]'>50%</p>
                        <p className='text-white text-[14px] roboto mt-[41px]'>{t('accounttypes.45')}</p>
                        <p className='text-white text-[14px] roboto mt-[20px]'>25/15</p>
                        <p className='text-white text-[14px] roboto mt-[20px]'>{t('accounttypes.46')}</p>
                        <p className='text-white text-[14px] roboto mt-[20px] mx-[25px] text-center'>{t('accounttypes.47')}</p>
                    </div>
                    <div className='w-[267px] h-[645px] rounded-[20px] bg-transparent flex flex-col items-center'>
                        <p className='roboto text-[14px] font-[500] text-[#00FFF0] mt-[40px]'>{t('accounttypes.48')}</p>
                        <p className='text-white text-[14px] roboto mt-[20px]'>USD 10.000</p>
                        <p className='text-white text-[14px] roboto mt-[20px]'>0 pips</p>
                        <p className='text-white text-[14px] roboto mt-[20px]'>0.01 lots</p>
                        <p className='text-white text-[14px] roboto mt-[20px] text-center mx-[20px]'>{t('accounttypes.49')}</p>
                        <p className='text-white text-[14px] roboto mt-[22px] text-center mx-[12px]'>-</p>
                        <p className='text-white text-[14px] roboto mt-[41px]'>{t('accounttypes.50')}</p>
                        <p className='text-white text-[14px] roboto mt-[20px]'>50%</p>
                        <p className='text-white text-[14px] roboto mt-[41px]'>{t('accounttypes.51')}</p>
                        <p className='text-white text-[14px] roboto mt-[20px]'>25/15</p>
                        <p className='text-white text-[14px] roboto mt-[20px]'>{t('accounttypes.52')}</p>
                        <p className='text-white text-[14px] roboto mt-[20px] mx-[25px] text-center'>{t('accounttypes.53')}</p>
                    </div>
                </div>
                <div className='block xl:hidden mt-[20px]'>
                    <Swiper
                        modules={[Navigation]}
                        slidesPerView={1}
                        onInit={(e) => {
                            setSwiper(e);
                        }}
                        loop={true}
                        breakpoints={{
                            640: { 
                                slidesPerView: 2,
                            }
                        }}
                    >
                        <SwiperSlide>
                            <div className='h-[559px] mx-[20px] rounded-[20px] bg-[#03192F]'>
                                <p className='roboto text-[16px] font-[500] text-[#00FFF0] text-center pt-[15px]'>{t('accounttypes.30')}</p>
                                <div className='grid grid-cols-2 gap-y-[20px] mx-[20px] py-[10px] pt-[20px]'>
                                    <p className='text-white font-[300] roboto text-[12px] w-[110px]'>{t('accounttypes.19')}</p>
                                    <p className='text-white font-[400] roboto text-[12px] mx-auto'>USD 250</p>
                                </div>
                                <div className='bg-[#FFFFFF1A] h-[1px] mx-[20px]'></div>
                                <div className='grid grid-cols-2 gap-y-[20px] mx-[20px] py-[10px]'>
                                    <p className='text-white font-[300] roboto text-[12px] w-[110px]'>{t('accounttypes.20')}</p>
                                    <p className='text-white font-[400] roboto text-[12px] mx-auto'>0.5 pips</p>
                                </div>
                                <div className='bg-[#FFFFFF1A] h-[1px] mx-[20px]'></div>
                                <div className='grid grid-cols-2 gap-y-[20px] mx-[20px] py-[10px]'>
                                    <p className='text-white font-[300] roboto text-[12px] w-[110px]'>{t('accounttypes.21')}</p>
                                    <p className='text-white font-[400] roboto text-[12px] mx-auto'>0.01 lots</p>
                                </div>
                                <div className='bg-[#FFFFFF1A] h-[1px] mx-[20px]'></div>
                                <div className='grid grid-cols-2 gap-y-[20px] mx-[20px] py-[10px]'>
                                    <p className='text-white font-[300] roboto text-[12px] w-[140px]'>{t('accounttypes.22')}</p>
                                    <p className='text-white font-[400] roboto text-[12px] mx-auto'>-</p>
                                </div>
                                <div className='bg-[#FFFFFF1A] h-[1px] mx-[20px]'></div>
                                <div className='grid grid-cols-2 gap-y-[20px] mx-[20px] py-[10px]'>
                                    <p className='text-white font-[300] roboto text-[12px] w-[140px]'>{t('accounttypes.23')}</p>
                                    <p className='text-white font-[400] roboto text-[12px] mx-auto w-[130px] text-center'>{t('accounttypes.31')}</p>
                                </div>
                                <div className='bg-[#FFFFFF1A] h-[1px] mx-[20px]'></div>
                                <div className='grid grid-cols-2 gap-y-[20px] mx-[20px] py-[10px]'>
                                    <p className='text-white font-[300] roboto text-[12px]'>{t('accounttypes.24')}</p>
                                    <p className='text-white font-[400] roboto text-[12px] mx-auto'>{t('accounttypes.32')}</p>
                                </div>
                                <div className='bg-[#FFFFFF1A] h-[1px] mx-[20px]'></div>
                                <div className='grid grid-cols-2 gap-y-[20px] mx-[20px] py-[10px]'>
                                    <p className='text-white font-[300] roboto text-[12px] w-[128px]'>{t('accounttypes.25')}</p>
                                    <p className='text-white font-[400] roboto text-[12px] mx-auto'>50%</p>
                                </div>
                                <div className='bg-[#FFFFFF1A] h-[1px] mx-[20px]'></div>
                                <div className='grid grid-cols-2 gap-y-[20px] mx-[20px] py-[10px]'>
                                    <p className='text-white font-[300] roboto text-[12px]'>{t('accounttypes.27')}</p>
                                    <p className='text-white font-[400] roboto text-[12px] mx-auto'>25/15</p>
                                </div>
                                <div className='bg-[#FFFFFF1A] h-[1px] mx-[20px]'></div>
                                <div className='grid grid-cols-2 gap-y-[20px] mx-[20px] py-[10px]'>
                                    <p className='text-white font-[300] roboto text-[12px]'>{t('accounttypes.28')}</p>
                                    <p className='text-white font-[400] roboto text-[12px] mx-auto'>{t('accounttypes.34')}</p>
                                </div>
                                <div className='bg-[#FFFFFF1A] h-[1px] mx-[20px]'></div>
                                <div className='grid grid-cols-2 gap-y-[20px] mx-[20px] py-[10px]'>
                                    <p className='text-white font-[300] roboto text-[12px]'>{t('accounttypes.29')}</p>
                                    <p className='text-white font-[400] roboto text-[12px] mx-auto w-[130px] text-center'>{t('accounttypes.35')}</p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='h-[559px] mx-[20px] rounded-[20px] bg-[#03192F]'>
                                <p className='roboto text-[16px] font-[500] text-[#00FFF0] text-center pt-[15px]'>{t('accounttypes.36')}</p>
                                <div className='grid grid-cols-2 gap-y-[20px] mx-[20px] py-[10px] pt-[20px]'>
                                    <p className='text-white font-[300] roboto text-[12px] w-[110px]'>{t('accounttypes.19')}</p>
                                    <p className='text-white font-[400] roboto text-[12px] mx-auto'>USD 2.500</p>
                                </div>
                                <div className='bg-[#FFFFFF1A] h-[1px] mx-[20px]'></div>
                                <div className='grid grid-cols-2 gap-y-[20px] mx-[20px] py-[10px]'>
                                    <p className='text-white font-[300] roboto text-[12px] w-[110px]'>{t('accounttypes.20')}</p>
                                    <p className='text-white font-[400] roboto text-[12px] mx-auto'>1.5 pips</p>
                                </div>
                                <div className='bg-[#FFFFFF1A] h-[1px] mx-[20px]'></div>
                                <div className='grid grid-cols-2 gap-y-[20px] mx-[20px] py-[10px]'>
                                    <p className='text-white font-[300] roboto text-[12px] w-[110px]'>{t('accounttypes.21')}</p>
                                    <p className='text-white font-[400] roboto text-[12px] mx-auto'>0.01 lots</p>
                                </div>
                                <div className='bg-[#FFFFFF1A] h-[1px] mx-[20px]'></div>
                                <div className='grid grid-cols-2 gap-y-[20px] mx-[20px] py-[10px]'>
                                    <p className='text-white font-[300] roboto text-[12px] w-[140px]'>{t('accounttypes.22')}</p>
                                    <p className='text-white font-[400] roboto text-[12px] mx-auto'>-</p>
                                </div>
                                <div className='bg-[#FFFFFF1A] h-[1px] mx-[20px]'></div>
                                <div className='grid grid-cols-2 gap-y-[20px] mx-[20px] py-[10px]'>
                                    <p className='text-white font-[300] roboto text-[12px] w-[140px]'>{t('accounttypes.23')}</p>
                                    <p className='text-white font-[400] roboto text-[12px] mx-auto w-[130px] text-center'>{t('accounttypes.37')}</p>
                                </div>
                                <div className='bg-[#FFFFFF1A] h-[1px] mx-[20px]'></div>
                                <div className='grid grid-cols-2 gap-y-[20px] mx-[20px] py-[10px]'>
                                    <p className='text-white font-[300] roboto text-[12px]'>{t('accounttypes.24')}</p>
                                    <p className='text-white font-[400] roboto text-[12px] mx-auto'>{t('accounttypes.38')}</p>
                                </div>
                                <div className='bg-[#FFFFFF1A] h-[1px] mx-[20px]'></div>
                                <div className='grid grid-cols-2 gap-y-[20px] mx-[20px] py-[10px]'>
                                    <p className='text-white font-[300] roboto text-[12px] w-[128px]'>{t('accounttypes.25')}</p>
                                    <p className='text-white font-[400] roboto text-[12px] mx-auto'>50%</p>
                                </div>
                                <div className='bg-[#FFFFFF1A] h-[1px] mx-[20px]'></div>
                                <div className='grid grid-cols-2 gap-y-[20px] mx-[20px] py-[10px]'>
                                    <p className='text-white font-[300] roboto text-[12px]'>{t('accounttypes.27')}</p>
                                    <p className='text-white font-[400] roboto text-[12px] mx-auto'>25/15</p>
                                </div>
                                <div className='bg-[#FFFFFF1A] h-[1px] mx-[20px]'></div>
                                <div className='grid grid-cols-2 gap-y-[20px] mx-[20px] py-[10px]'>
                                    <p className='text-white font-[300] roboto text-[12px]'>{t('accounttypes.28')}</p>
                                    <p className='text-white font-[400] roboto text-[12px] mx-auto'>{t('accounttypes.40')}</p>
                                </div>
                                <div className='bg-[#FFFFFF1A] h-[1px] mx-[20px]'></div>
                                <div className='grid grid-cols-2 gap-y-[20px] mx-[20px] py-[10px]'>
                                    <p className='text-white font-[300] roboto text-[12px]'>{t('accounttypes.29')}</p>
                                    <p className='text-white font-[400] roboto text-[12px] mx-auto w-[130px] text-center'>{t('accounttypes.41')}</p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='h-[559px] mx-[20px] rounded-[20px] bg-[#03192F]'>
                                <p className='roboto text-[16px] font-[500] text-[#00FFF0] text-center pt-[15px]'>{t('accounttypes.42')}</p>
                                <div className='grid grid-cols-2 gap-y-[20px] mx-[20px] py-[10px] pt-[20px]'>
                                    <p className='text-white font-[300] roboto text-[12px] w-[110px]'>{t('accounttypes.19')}</p>
                                    <p className='text-white font-[400] roboto text-[12px] mx-auto'>USD 5.000</p>
                                </div>
                                <div className='bg-[#FFFFFF1A] h-[1px] mx-[20px]'></div>
                                <div className='grid grid-cols-2 gap-y-[20px] mx-[20px] py-[10px]'>
                                    <p className='text-white font-[300] roboto text-[12px] w-[110px]'>{t('accounttypes.20')}</p>
                                    <p className='text-white font-[400] roboto text-[12px] mx-auto'>0 pips</p>
                                </div>
                                <div className='bg-[#FFFFFF1A] h-[1px] mx-[20px]'></div>
                                <div className='grid grid-cols-2 gap-y-[20px] mx-[20px] py-[10px]'>
                                    <p className='text-white font-[300] roboto text-[12px] w-[110px]'>{t('accounttypes.21')}</p>
                                    <p className='text-white font-[400] roboto text-[12px] mx-auto'>0.01 lots</p>
                                </div>
                                <div className='bg-[#FFFFFF1A] h-[1px] mx-[20px]'></div>
                                <div className='grid grid-cols-2 gap-y-[20px] mx-[20px] py-[10px]'>
                                    <p className='text-white font-[300] roboto text-[12px] w-[140px]'>{t('accounttypes.22')}</p>
                                    <p className='text-white font-[400] roboto text-[12px] mx-auto text-center'>{t('accounttypes.43')}</p>
                                </div>
                                <div className='bg-[#FFFFFF1A] h-[1px] mx-[20px]'></div>
                                <div className='grid grid-cols-2 gap-y-[20px] mx-[20px] py-[10px]'>
                                    <p className='text-white font-[300] roboto text-[12px] w-[140px]'>{t('accounttypes.23')}</p>
                                    <p className='text-white font-[400] roboto text-[12px] mx-auto w-[130px] text-center'>-</p>
                                </div>
                                <div className='bg-[#FFFFFF1A] h-[1px] mx-[20px]'></div>
                                <div className='grid grid-cols-2 gap-y-[20px] mx-[20px] py-[10px]'>
                                    <p className='text-white font-[300] roboto text-[12px]'>{t('accounttypes.24')}</p>
                                    <p className='text-white font-[400] roboto text-[12px] mx-auto'>{t('accounttypes.44')}</p>
                                </div>
                                <div className='bg-[#FFFFFF1A] h-[1px] mx-[20px]'></div>
                                <div className='grid grid-cols-2 gap-y-[20px] mx-[20px] py-[10px]'>
                                    <p className='text-white font-[300] roboto text-[12px] w-[128px]'>{t('accounttypes.25')}</p>
                                    <p className='text-white font-[400] roboto text-[12px] mx-auto'>50%</p>
                                </div>
                                <div className='bg-[#FFFFFF1A] h-[1px] mx-[20px]'></div>
                                <div className='grid grid-cols-2 gap-y-[20px] mx-[20px] py-[10px]'>
                                    <p className='text-white font-[300] roboto text-[12px]'>{t('accounttypes.27')}</p>
                                    <p className='text-white font-[400] roboto text-[12px] mx-auto'>25/15</p>
                                </div>
                                <div className='bg-[#FFFFFF1A] h-[1px] mx-[20px]'></div>
                                <div className='grid grid-cols-2 gap-y-[20px] mx-[20px] py-[10px]'>
                                    <p className='text-white font-[300] roboto text-[12px]'>{t('accounttypes.28')}</p>
                                    <p className='text-white font-[400] roboto text-[12px] mx-auto'>{t('accounttypes.46')}</p>
                                </div>
                                <div className='bg-[#FFFFFF1A] h-[1px] mx-[20px]'></div>
                                <div className='grid grid-cols-2 gap-y-[20px] mx-[20px] py-[10px]'>
                                    <p className='text-white font-[300] roboto text-[12px]'>{t('accounttypes.29')}</p>
                                    <p className='text-white font-[400] roboto text-[12px] mx-auto w-[130px] text-center'>{t('accounttypes.47')}</p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='h-[559px] mx-[20px] rounded-[20px] bg-[#03192F]'>
                                <p className='roboto text-[16px] font-[500] text-[#00FFF0] text-center pt-[15px]'>{t('accounttypes.48')}</p>
                                <div className='grid grid-cols-2 gap-y-[20px] mx-[20px] py-[10px] pt-[20px]'>
                                    <p className='text-white font-[300] roboto text-[12px] w-[110px]'>{t('accounttypes.19')}</p>
                                    <p className='text-white font-[400] roboto text-[12px] mx-auto'>USD 10.000</p>
                                </div>
                                <div className='bg-[#FFFFFF1A] h-[1px] mx-[20px]'></div>
                                <div className='grid grid-cols-2 gap-y-[20px] mx-[20px] py-[10px]'>
                                    <p className='text-white font-[300] roboto text-[12px] w-[110px]'>{t('accounttypes.20')}</p>
                                    <p className='text-white font-[400] roboto text-[12px] mx-auto'>0 pips</p>
                                </div>
                                <div className='bg-[#FFFFFF1A] h-[1px] mx-[20px]'></div>
                                <div className='grid grid-cols-2 gap-y-[20px] mx-[20px] py-[10px]'>
                                    <p className='text-white font-[300] roboto text-[12px] w-[110px]'>{t('accounttypes.21')}</p>
                                    <p className='text-white font-[400] roboto text-[12px] mx-auto'>0.01 lots</p>
                                </div>
                                <div className='bg-[#FFFFFF1A] h-[1px] mx-[20px]'></div>
                                <div className='grid grid-cols-2 gap-y-[20px] mx-[20px] py-[10px]'>
                                    <p className='text-white font-[300] roboto text-[12px] w-[140px]'>{t('accounttypes.22')}</p>
                                    <p className='text-white font-[400] roboto text-[12px] mx-auto text-center'>{t('accounttypes.49')}</p>
                                </div>
                                <div className='bg-[#FFFFFF1A] h-[1px] mx-[20px]'></div>
                                <div className='grid grid-cols-2 gap-y-[20px] mx-[20px] py-[10px]'>
                                    <p className='text-white font-[300] roboto text-[12px] w-[140px]'>{t('accounttypes.23')}</p>
                                    <p className='text-white font-[400] roboto text-[12px] mx-auto w-[130px] text-center'>-</p>
                                </div>
                                <div className='bg-[#FFFFFF1A] h-[1px] mx-[20px]'></div>
                                <div className='grid grid-cols-2 gap-y-[20px] mx-[20px] py-[10px]'>
                                    <p className='text-white font-[300] roboto text-[12px]'>{t('accounttypes.24')}</p>
                                    <p className='text-white font-[400] roboto text-[12px] mx-auto'>{t('accounttypes.50')}</p>
                                </div>
                                <div className='bg-[#FFFFFF1A] h-[1px] mx-[20px]'></div>
                                <div className='grid grid-cols-2 gap-y-[20px] mx-[20px] py-[10px]'>
                                    <p className='text-white font-[300] roboto text-[12px] w-[128px]'>{t('accounttypes.25')}</p>
                                    <p className='text-white font-[400] roboto text-[12px] mx-auto'>50%</p>
                                </div>
                                <div className='bg-[#FFFFFF1A] h-[1px] mx-[20px]'></div>
                                <div className='grid grid-cols-2 gap-y-[20px] mx-[20px] py-[10px]'>
                                    <p className='text-white font-[300] roboto text-[12px]'>{t('accounttypes.27')}</p>
                                    <p className='text-white font-[400] roboto text-[12px] mx-auto'>25/15</p>
                                </div>
                                <div className='bg-[#FFFFFF1A] h-[1px] mx-[20px]'></div>
                                <div className='grid grid-cols-2 gap-y-[20px] mx-[20px] py-[10px]'>
                                    <p className='text-white font-[300] roboto text-[12px]'>{t('accounttypes.28')}</p>
                                    <p className='text-white font-[400] roboto text-[12px] mx-auto'>{t('accounttypes.52')}</p>
                                </div>
                                <div className='bg-[#FFFFFF1A] h-[1px] mx-[20px]'></div>
                                <div className='grid grid-cols-2 gap-y-[20px] mx-[20px] py-[10px]'>
                                    <p className='text-white font-[300] roboto text-[12px]'>{t('accounttypes.29')}</p>
                                    <p className='text-white font-[400] roboto text-[12px] mx-auto w-[130px] text-center'>{t('accounttypes.53')}</p>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                    <div className='flex justify-between mx-[20px] mt-[10px]'>
                        <div onClick={() => { swiper1.slidePrev(); }} className="cursor-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                                <g filter="url(#filter0_bf_1545_96)">
                                    <rect x="4" y="4" width="40" height="40" rx="20" fill="white" fill-opacity="0.06"/>
                                    <rect x="4.5" y="4.5" width="39" height="39" rx="19.5" stroke="white"/>
                                </g>
                                <path d="M15 24L20 26.8868L20 21.1132L15 24ZM33 23.5L19.5 23.5L19.5 24.5L33 24.5L33 23.5Z" fill="white"/>
                                <defs>
                                    <filter id="filter0_bf_1545_96" x="-18" y="-18" width="84" height="84" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="11"/>
                                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1545_96"/>
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_1545_96" result="shape"/>
                                    <feGaussianBlur stdDeviation="2" result="effect2_foregroundBlur_1545_96"/>
                                    </filter>
                                </defs>
                            </svg>
                        </div>
                        <div onClick={() => { swiper1.slideNext() }} className="cursor-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                                <g filter="url(#filter0_bf_1545_93)">
                                    <rect x="44" y="44" width="40" height="40" rx="20" transform="rotate(180 44 44)" fill="white" fill-opacity="0.06"/>
                                    <rect x="43.5" y="43.5" width="39" height="39" rx="19.5" transform="rotate(180 43.5 43.5)" stroke="white"/>
                                </g>
                                <path d="M33 24L28 21.1132L28 26.8867L33 24ZM15 24.5L28.5 24.5L28.5 23.5L15 23.5L15 24.5Z" fill="white"/>
                                <defs>
                                    <filter id="filter0_bf_1545_93" x="-18" y="-18" width="84" height="84" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="11"/>
                                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1545_93"/>
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_1545_93" result="shape"/>
                                    <feGaussianBlur stdDeviation="2" result="effect2_foregroundBlur_1545_93"/>
                                    </filter>
                                </defs>
                            </svg>
                        </div>
                    </div> 
                </div>    
            </div>
            <Footer/>
        </div>
        
    );
}

export default AccountComparison;