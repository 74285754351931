import React, { useState } from 'react';

import swiper1 from '../../img/AboutUs/swiper1.png'
import swiper2 from '../../img/AboutUs/swiper2.png'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from "swiper/modules";

import { useTranslation } from "react-i18next";

import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css';

function ABrandBuilt () {

    const [swiper, setSwiper] = useState({});

    const { t } = useTranslation();

    return(
        <div className='max-w-[1920px] mx-auto overflow-hidden relative mt-[160px] xl:mt-0'>
            <div className='max-w-[1220px] mx-[20px] xl:mx-auto relative xl:h-[760px]'>
                <p className='text-white uppercase russo-one text-[20px] xl:text-[32px] text-center'>{t('aboutus.10')}</p>
                <p className='max-w-[497px] text-white roboto font-[300] text-[14px] xl:text-[16px] text-center mx-auto mt-[12px]'>{t('aboutus.11')}</p>
                <img src={swiper1} alt=""  className='mx-auto absolute left-1/2 -translate-x-1/2'/>
                <img src={swiper2} alt="" className='mx-auto absolute top-[300px] xl:top-[500px] left-1/2 -translate-x-1/2'/>
                <Swiper
                    modules={[Navigation]}
                    slidesPerView={1}
                    onInit={(e) => {
                        setSwiper(e);
                    }}
                    loop={true}
                    className='mt-[120px] xl:mt-[250px] w-[350px]'
                >
                    <SwiperSlide>
                        <div className='w-[300px] h-[150px] mx-auto'>
                            <p className='uppercase text-[16px] xl:text-[24px] text-white russo-one text-center'>{t('aboutus.12')}</p>
                            <p className='max-w-[180px] xl:max-w-[300px] text-white roboto text-[14px] xl:text-[20px] mt-[14px] text-center mx-auto'>{t('aboutus.13')}</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='w-[300px] h-[150px] mx-auto'>
                            <p className='uppercase text-[16px] xl:text-[24px] text-white russo-one text-center'>{t('aboutus.14')}</p>
                            <p className='max-w-[180px] xl:max-w-[300px] text-white roboto text-[14px] xl:text-[20px] mt-[14px] text-center mx-auto'>{t('aboutus.15')}</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='w-[300px] h-[150px] mx-auto'>
                            <p className='uppercase text-[16px] xl:text-[24px] text-white russo-one text-center'>{t('aboutus.16')}</p>
                            <p className='max-w-[180px] xl:max-w-[300px] text-white roboto text-[14px] xl:text-[20px] mt-[14px] text-center mx-auto'>{t('aboutus.17')}</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='w-[300px] h-[150px] mx-auto'>
                            <p className='uppercase text-[16px] xl:text-[24px] text-white russo-one text-center'>{t('aboutus.18')}</p>
                            <p className='max-w-[180px] xl:max-w-[300px] text-white roboto text-[14px] xl:text-[20px] mt-[14px] text-center mx-auto'>{t('aboutus.19')}</p>
                        </div>
                    </SwiperSlide>
                </Swiper>
                <div className='flex justify-between xl:w-[800px] xl:mx-auto mx-[20px] mt-[80px] xl:mt-[-100px] z-[609] relative'>
                    <div onClick={() => { swiper.slidePrev(); }} className="cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                            <g filter="url(#filter0_bf_1545_96)">
                                <rect x="4" y="4" width="40" height="40" rx="20" fill="white" fill-opacity="0.06"/>
                                <rect x="4.5" y="4.5" width="39" height="39" rx="19.5" stroke="white"/>
                            </g>
                            <path d="M15 24L20 26.8868L20 21.1132L15 24ZM33 23.5L19.5 23.5L19.5 24.5L33 24.5L33 23.5Z" fill="white"/>
                            <defs>
                                <filter id="filter0_bf_1545_96" x="-18" y="-18" width="84" height="84" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                <feGaussianBlur in="BackgroundImageFix" stdDeviation="11"/>
                                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1545_96"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_1545_96" result="shape"/>
                                <feGaussianBlur stdDeviation="2" result="effect2_foregroundBlur_1545_96"/>
                                </filter>
                            </defs>
                        </svg>
                    </div>
                    <div onClick={() => { swiper.slideNext() }} className="cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                            <g filter="url(#filter0_bf_1545_93)">
                                <rect x="44" y="44" width="40" height="40" rx="20" transform="rotate(180 44 44)" fill="white" fill-opacity="0.06"/>
                                <rect x="43.5" y="43.5" width="39" height="39" rx="19.5" transform="rotate(180 43.5 43.5)" stroke="white"/>
                            </g>
                            <path d="M33 24L28 21.1132L28 26.8867L33 24ZM15 24.5L28.5 24.5L28.5 23.5L15 23.5L15 24.5Z" fill="white"/>
                            <defs>
                                <filter id="filter0_bf_1545_93" x="-18" y="-18" width="84" height="84" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                <feGaussianBlur in="BackgroundImageFix" stdDeviation="11"/>
                                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1545_93"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_1545_93" result="shape"/>
                                <feGaussianBlur stdDeviation="2" result="effect2_foregroundBlur_1545_93"/>
                                </filter>
                            </defs>
                        </svg>
                    </div>
                </div> 
            </div>
        </div>
    );
}

export default ABrandBuilt;