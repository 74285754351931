import React from 'react';
import Fade from 'react-reveal/Fade'

import tablet from '../../img/MarginTrading/tablet.png'
import glass from '../../img/MarginTrading/glass.png'
import bg from '../../img/MarginTrading/bg.png'

import { useTranslation } from "react-i18next";

function ShortSelling () {

    const { t } = useTranslation();

    return(
        <div className="bg-[#000C17] relative pt-[80px] xl:pt-[160px] mt-[-5px] pb-[5px]">
            <div className='max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col'>
                <div className='flex flex-col xl:flex-row justify-between'>
                    <div>
                        <h1 className="russo-one text-white text-[20px] md:text-[24px] xl:text-[48px]">{t('margintrading.32')}</h1>
                        <p className='font-[300] xl:max-w-[600px] text-[#FFF] roboto text-[14px] xl:text-[16px] mt-[20px]'>{t('margintrading.33')}</p>
                        <div className='xl:w-[600px] h-[232px] rounded-[20px] relative overflow-hidden mt-[20px] xl:mt-[50px]'>
                            <img src={bg} alt="" className='object-cover w-full h-full absolute'/>
                            <div className='relative'>
                                <p className='font-[500] xl:max-w-[600px] text-[#00FFF0] russo-one text-[16px] xl:text-[20px] mt-[40px] uppercase mx-[20px] xl:mx-[60px]'>Simple Pricing</p>
                                <div className='grid grid-cols-2 mx-[20px] xl:mx-[60px] mt-[20px] xl:mt-[27px] gap-[80px]'>
                                    <div>
                                        <p className='text-white text-[14px] xl:text-[16px] font-[500] roboto'>{t('margintrading.34')}</p>
                                        <p className='text-white text-[14px] xl:text-[16px] font-[500] roboto'>$0</p>
                                        <p className='text-white text-[14px] xl:text-[16px] font-[500] roboto'>{t('margintrading.35')}</p>
                                    </div>
                                    <div>
                                        <p className='text-white text-[14px] xl:text-[16px] font-[500] roboto'>{t('margintrading.36')}</p>
                                        <p className='text-white text-[14px] xl:text-[16px] font-[500] roboto'>$0</p>
                                        <p className='text-white text-[14px] xl:text-[16px] font-[500] roboto'>{t('margintrading.37')}</p>
                                        <p className='text-white text-[14px] xl:text-[16px] font-[500] roboto'>{t('margintrading.38')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='relative'>
                        <img src={glass} alt="" className='absolute'/>
                        <Fade right>
                            <img src={tablet} alt="" className='xl:mt-[-150px] z-50 relative'/>
                        </Fade>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ShortSelling;